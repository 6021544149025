import { inject } from '@angular/core';
import type { ActivatedRouteSnapshot } from '@angular/router';
import { type ApiTypes, VehicleFeature } from '@hyundai/ng-common-lib';
import { Store } from '@ngrx/store';
import { filter, firstValueFrom } from 'rxjs';

export function vehicleMarketTitleResolver(
    title: (vehicle: ApiTypes.VehicleDetail, store: Store) => Promise<string> | string,
) {
    return async () => {
        const store = inject(Store);
        const vehicle = await firstValueFrom(
            store.select(VehicleFeature.vehicleState.selectLoadedDetail).pipe(filter((d) => !!d)),
        );
        return `${await title(vehicle, store)}`;
    };
}

export function distributionPageTitleResolver(route: ActivatedRouteSnapshot) {
    const fragment = route.fragment ?? undefined;

    const distributionTitle = distributionPageTitleFromFragment(fragment);
    return vehicleMarketTitleResolver(
        (vehicle) => `${distributionTitle} | ${vehicle.dealer.name} ${vehicle.dealer.location.city}`,
    );
}

export function distributionPageTitleFromFragment(fragment?: string): string {
    switch (fragment) {
        case 'probefahrt': {
            return 'Hyundai Probefahrtanfrage';
        }
        case 'anfrage': {
            return 'Hyundai Allgemeine Anfrage';
        }
        case 'reservierung': {
            return 'Hyundai Reservierungsanfrage ';
        }
        default: {
            return 'Hyundai Händleranfrage';
        }
    }
}
