import type { ActivatedRouteSnapshot, Routes } from '@angular/router';
import {
    type ApiTypes,
    createMetaResolver,
    DealerFeature,
    defaultTitleResolver,
    DistributionFeature,
    FootnotesFeature,
    ImprintPageFeature,
    isHWS,
    ngrxLoaderGuard,
    PageContentType,
    VehicleFeature,
} from '@hyundai/ng-common-lib';
import { provideEffects } from '@ngrx/effects';
import { getRouterSelectors } from '@ngrx/router-store';
import { provideState } from '@ngrx/store';
import { filter, firstValueFrom } from 'rxjs';
import { distributionPageTitleResolver, vehicleMarketTitleResolver } from '../../utils/title-resolver';

const { selectQueryParam } = getRouterSelectors();
export const vehicleProvider = [provideState(VehicleFeature.vehicleState), provideEffects(VehicleFeature.effects)];
export const distributionProvider = [
    provideState(DistributionFeature.distributionState),
    provideEffects(DistributionFeature.effects),
];
export const footnotesProvider = [provideState(ImprintPageFeature.state), provideEffects(ImprintPageFeature.effects)];
export const imprintProvider = [provideState(FootnotesFeature.state), provideEffects(FootnotesFeature.effects)];

export default [
    {
        path: '',
        loadComponent: () => import('./list-page.component'),
        canActivate: [
            ngrxLoaderGuard({
                waitUntilLoadedSelector: VehicleFeature.vehicleState.selectIsLoadingList,
                dataToCheckSelector: VehicleFeature.vehicleState.selectList,
                actionToDispatch: (route) => {
                    const filterQueryParam = route.queryParams['filter'] ?? undefined;

                    if (filterQueryParam !== undefined) {
                        try {
                            return VehicleFeature.vehicleActions.init({ initalFilter: JSON.parse(filterQueryParam) });
                        } catch {
                            return VehicleFeature.vehicleActions.init({});
                        }
                    }

                    return VehicleFeature.vehicleActions.init({});
                },
            }),
            ngrxLoaderGuard({
                waitUntilLoadedSelector: VehicleFeature.vehicleState.selectIsLoadingNumberOfVehicles,
                dataToCheckSelector: VehicleFeature.vehicleState.selectNumberOfVehicles,
            }),
        ],
        title: async (route) => {
            const filterQueryParam = route.queryParams['filter']
                ? JSON.stringify(route.queryParams['filter'])
                : undefined;
            if (isHWS()) {
                let preText = 'Unser Fahrzeugbestand';
                if (filterQueryParam) {
                    if (filterQueryParam.includes('Gebrauchtfahrzeug')) preText = 'Unsere Gebrauchtwagen';
                    if (filterQueryParam.includes('Elektro')) preText = 'Unser Elektro-Fahrzeugbestand';
                    if (filterQueryParam.includes('Neufahrzeug')) preText = 'Unser Neuwagenbestand';
                }

                return defaultTitleResolver(preText)();
            }

            return filterQueryParam === undefined
                ? 'Hyundai Fahrzeugbestand'
                : 'Hyundai Fahrzeugbestand Suchergebnisse';
        },
        resolve: {
            meta: createMetaResolver(async (store, isHWS) => {
                const filterQueryParam = await firstValueFrom(store.select(selectQueryParam('filter')));

                if (isHWS) {
                    const dealer = await firstValueFrom(
                        store.select(DealerFeature.state.selectLoadedDealer).pipe(filter((d) => !!d)),
                    );
                    return {
                        description: getDescription(filterQueryParam, dealer),
                    };
                }

                return {
                    description: getDescription(filterQueryParam),
                };
            }),
        },
        pathMatch: 'full',
        providers: [vehicleProvider],
        runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
        data: {
            contentType: PageContentType.VEHICLELIST,
        },
    },
    {
        path: ':slug',
        loadComponent: () => import('./detail-page.component'),
        providers: [vehicleProvider, imprintProvider, footnotesProvider],
        canActivate: [
            ngrxLoaderGuard({
                waitUntilLoadedSelector: VehicleFeature.vehicleState.selectIsLoadingDetail,
                dataToCheckSelector: VehicleFeature.vehicleState.selectDetail,
                actionToDispatch: (route) =>
                    VehicleFeature.vehicleActions.loadVehicleDetail({ slug: route.params['slug'] }),
            }),
        ],
        title: () => {
            const hws = isHWS();
            return vehicleMarketTitleResolver(
                (vehicle) =>
                    `${hws ? '' : 'Hyundai '}${vehicle.model} | ${vehicle.dealer.name} ${vehicle.dealer.location.city}`,
            )();
        },
        resolve: {
            meta: createMetaResolver(async (store, isHWS) => {
                const vehicle = await firstValueFrom(store.select(VehicleFeature.vehicleState.selectLoadedDetail));

                if (isHWS) {
                    const dealer = await firstValueFrom(
                        store.select(DealerFeature.state.selectLoadedDealer).pipe(filter((d) => !!d)),
                    );
                    return {
                        description: `Erleben Sie den ${vehicle.model} bei Ihrem Hyundai Händler ${dealer.name} in ${dealer.city} live. Jetzt Probefahrt vereinbaren!`,
                    };
                }
                return {
                    description: `Erleben Sie den ${vehicle.model} bei Ihrem Hyundai Händler ${vehicle.dealer.name}in ${vehicle.dealer.location.city} live. Jetzt Probefahrt vereinbaren!`,
                };
            }),
        },
        pathMatch: 'full',
        runGuardsAndResolvers: 'pathParamsChange',
        data: {
            contentType: PageContentType.VEHICLEDETAIL,
        },
    },
    {
        path: ':slug/haendleranfrage',
        loadComponent: () => import('../checkouts/distribution-page/distribution-page.component'),
        providers: [vehicleProvider, distributionProvider],
        canActivate: [
            ngrxLoaderGuard({
                waitUntilLoadedSelector: VehicleFeature.vehicleState.selectIsLoadingDetail,
                dataToCheckSelector: VehicleFeature.vehicleState.selectDetail,
                actionToDispatch: (route) =>
                    VehicleFeature.vehicleActions.loadVehicleDetail({ slug: route.params['slug'] }),
            }),
        ],
        title: (route: ActivatedRouteSnapshot) => distributionPageTitleResolver(route)(),
        resolve: {
            meta: createMetaResolver(async (store, isHWS) => {
                if (isHWS) {
                    const dealer = await firstValueFrom(
                        store.select(DealerFeature.state.selectLoadedDealer).pipe(filter((d) => !!d)),
                    );
                    return {
                        description: `Kontaktieren Sie jetzt Ihren Hyundai Partner ${dealer.name} in ${dealer.city} für Probefahrten, allgemeine Anfragen oder Reservierungen: Wir sind für Sie da!`,
                    };
                }

                const vehicle = await firstValueFrom(store.select(VehicleFeature.vehicleState.selectLoadedDetail));
                return {
                    description: `Kontaktieren Sie jetzt Ihren Hyundai Partner ${vehicle.dealer.name}in ${vehicle.dealer.location.city} für Probefahrten, allgemeine Anfragen oder Reservierungen: Wir sind für Sie da!`,
                };
            }),
        },
        pathMatch: 'full',
        data: {
            contentType: PageContentType.CARMARKETFORM,
        },
    },
] satisfies Routes;

function getDescription(filterQueryParam: string | string[] | undefined, dealer?: ApiTypes.Dealer | null) {
    if (dealer) {
        const postDealerText = `bei Ihrem Hyundai Partner ${dealer.name} in ${dealer.city}!`;
        if (!filterQueryParam) {
            return `Finden Sie jetzt Ihr Wunschmodell ${postDealerText}`;
        }

        const getDescription = (filter: { fuel?: string; condition?: string }) => {
            const fuel = filter.fuel;
            const condition = filter.condition;

            if (fuel?.includes('Elektro')) return `Entdecken Sie Elektro Neuwagen und Gebrauchtwagen ${postDealerText}`;
            if (condition?.includes('Gebrauchtfahrzeug'))
                return `Finden Sie jetzt Ihr Wunschmodell aus dem Hyundai Gebrauchtwagenbestand ${postDealerText}`;
            if (condition?.includes('Neufahrzeug'))
                return `Finden Sie jetzt Ihr Wunschmodell als Neuwagen ${postDealerText}`;

            return `Finden Sie jetzt Ihr Wunschmodell ${postDealerText}`;
        };

        if (!Array.isArray(filterQueryParam)) {
            const filter = JSON.parse(filterQueryParam);
            return getDescription(filter);
        }
    }

    return 'Finden Sie jetzt Ihr Hyundai Wunschmodell aus dem Hyundai Gebrauchtwagenprogramm bei Ihrem Hyundai Partner.';
}
